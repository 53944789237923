@keyframes spin {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.spinner {
    margin: 0 auto;
    width: 60px;
    height: 60px;
    border: 5px solid rgba(138, 26, 26, 0.3);
    border-radius: 50%;
    border-top-color: #8a1a1a;
    animation: spin infinite 0.7s linear;
}