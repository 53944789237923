@import './variables';

.button {
    min-width: 101px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    transition: 0.3s transform;
    border: none;
    background-color: transparent;
    cursor: pointer;
    &__long {
        display: block;
        width: 170px;
        margin: 45px auto 0 auto;
    }
    .inner {
        position: relative;
        background-color: $main-color;
        line-height: 18px;
        padding: 0 18px;
        transition: none;
        &::before {
            border-color: $main-color transparent;
            border-style: solid;
            border-width: 0 0 10px 10px;
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: -10px;
            transition: none;
        }
        &::after {
            border-color: $main-color transparent;
            border-style: solid;
            border-width: 0 0 10px 10px;
            content: "";
            display: block;
            position: absolute;
            right: 0;
            bottom: -10px;
            transform: rotate(180deg);
            transition: none;
        }
    }
    &__main, &__secondary {
        &:hover {
            color: #fff;
        }
    }
    &__secondary {
        .inner {
            background-color: $grey;
            &::before {
                border-color: $grey transparent;
                transition: none;
            }
            &::after {
                border-color: $grey transparent; 
                transition: none;
            }
        }
    }
    &::before {
        content: '';
        display: block;
        height: 10px;
        margin-left: 10px;
        transition: none;

    }
    &::after {
        content: '';
        display: block;
        height: 10px;
        margin-right: 10px;
        transition: none;

    }
    &.button__main::before, &.button__main::after {
        background-color: $main-color;
    }
    &.button__secondary::before, &.button__secondary::after {
        background-color: $grey;
    }
    &:hover {
        transform: translateY(-5px);
    }
    &:disabled{
        filter: grayscale(.5);
    }
}